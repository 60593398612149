<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Паспорт РО РСМ</h1>
      <button @click="onExport" type="button" class="link link--right">
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Скачать Word</span>
      </button>
    </div>
    <div class="content__title">
      <h2 class="title title--theme">Региональная организация: {{ regionOrgName }}</h2>
    </div>
    <div class="report report--indent">
      <div class="report__list">
        <router-link :to="{ name: 'passport-org' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>1</span>
          </div>
          <div class="report__title">Общие сведения об организации</div>
        </router-link>
        <router-link :to="{ name: 'passport-head' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>2</span>
          </div>
          <div class="report__title">Сведения о руководящих органах организации</div>
        </router-link>
        <router-link :to="{ name: 'passport-control' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>3</span>
          </div>
          <div class="report__title">Сведения о контрольно-ревизионном органе организации</div>
        </router-link>
        <router-link :to="{ name: 'passport-workers' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>4</span>
          </div>
          <div class="report__title">Работники аппарата региональной организации РСМ (включая работников на общественных
            началах)
          </div>
        </router-link>
        <router-link :to="{ name: 'passport-immovables' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>5</span>
          </div>
          <div class="report__title">Сведения о недвижимом имуществе, находящемся в собственности региональной
            организации РСМ
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Passport',
  computed: {
    regionOrgName() {
      return this.$store.state.organizationName
    },
    regionOrg() {
      return this.$store.state.organization
    },
  },
  methods: {
    onExport() {
      this.$store.dispatch('passport/GET_EXPORT')
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Паспорт_РО_${this.regionOrg}.docx`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    }
  }
}
</script>